<template>
  <div class="page-container common-form publish-news">
    <!-- header -->
    <el-card>
      <el-row>
        <el-col class="header-button">
          <el-button @click="closePublish">取消</el-button>
          <el-button type="primary" @click="addNewsHandle">立即发布</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-form ref="form" :model="formData" :rules="comptRules" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="新闻类型" prop="newsType">
                <el-select v-model="formData.newsType" filterable clearable>
                <span v-for="{paramName, paramValue} in newsType" :key="paramValue">
                  <el-option
                    :label="paramName"
                    :value="Number(paramValue)"
                    :key="paramValue"
                  />
                </span>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="新闻标题" prop="title">
                <el-input
                  type="textarea"
                  v-model.trim="formData.title"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="副标题" prop="previewText">
                <el-input
                  type="textarea"
                  v-model.trim="formData.previewText"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="previewImg">
                <template slot="label">
                  <tool-tips-group tips-content="建议封面尺寸：900px × 383px">
                    封面图片<br/>(900×383)
                  </tool-tips-group>
                </template>
                <single-img-uploader :url.sync="formData.previewImg" width="220px" height="94px" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="标签" prop="tag">
                <el-select v-model="formData.tag" filterable clearable>
                  <el-option
                    v-for="{paramName, paramValue} in newsTagType"
                    :label="paramName"
                    :value="paramValue"
                    :key="paramValue"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="标签颜色" prop="tagColor">
                <el-input class="color-input" type="color" v-model="formData.tagColor" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="阅读数" prop="viewTimes">
                <el-input-number :min="0" :max="9999" v-model.trim="formData.viewTimes" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否显示" prop="showFlag">
                <el-switch
                  v-model="formData.showFlag"
                  active-color="var(--color-success)"
                  :active-value="1"
                  active-text="是"
                  :inactive-value="0"
                  inactive-text="否" />
              </el-form-item>
            </el-col>
<!--            <el-col :span="8">-->
<!--              <el-form-item label="排序" prop="sort">-->
<!--                <el-input-number :min="0" :max="99999" v-model.trim="formData.sort" />-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-form-item label="公众号链接" prop="mpUrl">
            <el-input type="textarea" rows="2" v-model.trim="formData.mpUrl" />
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks" />
          </el-form-item>
        </el-form>
      </el-row>
      <!-- body -->
      <vue-tinymce @change="changeContent" :value="formData.content"
                   :imagesUploadHandler="imagesUploadHandler" />
    </el-card>
    <base-upload-file
      :uploadData="uploadData"
      @success="uploadImageSuccess"
      @close="closeUploadDialog" />
  </div>
</template>

<script>
import { addNews, getNewsById } from '@/api/news'
import { newsUploadImg } from '@/service/newsHttp'

const host = process.env.VUE_APP_FILE_URL
export default {
  data () {
    return {
      formData: {
        title: '', // 标题
        previewImg: '', // 预览图片
        previewText: '', // 副标题
        newsType: '', // 新闻类型
        content: '', // 文本内容
        mpUrl: '', // 公众号链接
        showFlag: 1, // 是否显示 1是 0否
        sort: 1, // 排序
        tag: '', // 新闻标签
        tagColor: '#2cd865', // 标签颜色
        viewTimes: null, // 阅读数
        remarks: ''
      },
      uploadData: {
        isOpen: false,
        title: '上传封面',
        type: ['image/png', 'image/jpeg'],
        size: 30,
        action: '/auth/upload/image'
      },
      newsType: [],
      newsTagType: []
    }
  },
  async mounted () {
    await Promise.all([
      this.$http.getParamListByKey('newsType', this, 'newsType'),
      this.$http.getParamListByKey('newsTagType', this, 'newsTagType')
    ])
    if (this.$route.query.id) {
      await this.getNewsById(this.$route.query.id)
    }
  },
  computed: {
    comptRules () {
      const _rules = {
        title: [{ required: true, message: '请填写新闻标题', trigger: 'blur' },
          {
            message: '不能超过110个字',
            trigger: 'blur',
            max: 110
          }],
        previewText: {
          message: '不能超过200个字',
          trigger: 'blur',
          max: 200
        },
        mpUrl: [{
          message: '不能超过1000个字',
          trigger: 'blur',
          max: 1000
        }, {
          validator: (rule, value, callback) => {
            if (value && !/^https:\/\/mp.weixin.qq.com\/[^\s]*$/.test(value)) {
              return callback(new Error('公众号地址格式不正确!'))
            } else {
              return callback()
            }
          },
          trigger: 'blur'
        }],
        newsType: [
          { required: true, message: '请选择新闻类型', trigger: 'blur' }
        ],
        remarks: {
          message: '不能超过200个字',
          trigger: 'blur',
          max: 200
        },
        content: {},
        viewTimes: this.$store.state.commonValidate.validatorNumberObj
      }
      if (this.formData.mpUrl) {
        _rules.content = {}
      } else {
        _rules.content = { required: true, message: '请填写新闻内容', trigger: 'blur' }
      }
      return _rules
    }
  },
  methods: {
    // 根据id获取新闻信息
    getNewsById (id) {
      getNewsById(id).then((res) => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
        this.formData.id = id
      })
    },
    // 编辑内容
    changeContent (richText) {
      this.formData.content = richText
    },
    // 上传图片成功
    uploadImageSuccess (data) {
      this.formData.previewImg = data.data
      this.closeUploadDialog()
    },
    // 发布新闻
    addNewsHandle () {
      if (!this.formData.content && !this.formData.mpUrl) {
        this.$message.error('请输入新闻内容或输入公众号链接!')
        return false
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formData.viewTimes = this.formData.viewTimes || Math.floor(Math.random() * 999)
          addNews(this.formData).then(() => {
            this.$message.success('添加成功')
            this.$router.push('/appManage/newsList')
          })
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    // 取消发布
    closePublish () {
      this.$router.push('/appManage/newsList')
    },
    // 关闭上传弹窗
    closeUploadDialog () {
      this.uploadData.isOpen = false
    },
    imagesUploadHandler (blobInfo, success, failure) {
      newsUploadImg(blobInfo.blob()).then(res => {
        success(host + res.data.data)
      })
    }
  }
}
</script>

<style lang="scss">
.publish-news {
  .color-input {
    .el-input__inner {
      padding: 0;
      border: 0;
    }
  }
}
</style>
