import request from '@/service/request'

// 获取新闻列表
export const pageNewsApi = (params) => {
  return request.post('/app/sysText/page', params)
}

// 添加新闻
export const addNews = (params) => {
  return request.post('/app/sysText/save', params)
}

// 删除新闻
export const deleteNews = (id) => {
  return request.post(`/app/sysText/del/${id}`)
}

// 根据id获取新闻信息
export const getNewsById = (id) => {
  return request.post(`/app/sysText/getById/${id}`)
}
